<template>
  <div>
    <div v-if="storeItems.length > 0">
      <div class="info-block">
        <span class="title" :class="{ 'text-light': !isLight() }">
          {{ `${$vueTranslate('store_items.preview_store_item.header')}s` }}
        </span>
        <div
          v-if="total_count > 4"
          class="link"
          :class="{ 'text-light': !isLight() }"
          @click="goToStoreItems()"
        >
          {{ `${$vueTranslate('store_items.preview_store_item.view_all')} >` }}
        </div>
      </div>
      <EntriesCarousel
        :items="storeItems"
        :total-count="total_count"
        :is-light="isLight()"
        @[EMITTERS.STORE_ITEM_PREVIEW_ALL]="goToStoreItems"
      />
      <PreviewStoreItem
        v-if="showPreview"
        :theme="getTheme()"
        :entity="storeItem"
        class="store-item-preview"
        @[EMITTERS.CLOSE_DIALOG_EMITTER]="closePreview"
      />
    </div>
    <div v-else-if="!isLight() && !isSeries()" class="empty-list">
      {{ NO_ITEMS }}
    </div>
  </div>
</template>

<script>
import EntriesCarousel from './components/EntriesCarousel'
import PreviewStoreItem from './components/PreviewStoreItem'
import { THEMES } from '../shared/utils/view_options'
import { EMITTERS } from '../shared/utils/emitters'
import storeItemsApiService from './services/storeItemsApi.service'

export default {
  name: 'App',
  components: { PreviewStoreItem, EntriesCarousel },
  props: {
    theme: {
      default: THEMES.LIGHT,
      type: String,
      required: false,
    },
  },
  data() {
    return {
      NO_ITEMS: "There're no store items yet",
      EMITTERS,
      showPreview: false,
      storeItem: {},
      storeItems: [],
      total_count: 0,
    }
  },
  mounted() {
    this.emitter.on(EMITTERS.STORE_ITEM_PREVIEW, this.openPreview)
    this.fetchItems()
  },
  methods: {
    isSeries() {
      return this.optional_params.entity_type === 'Series'
    },
    isLight() {
      return this.getTheme() === THEMES.LIGHT
    },
    getTheme() {
      if (this.optional_params.theme) return this.optional_params.theme

      return this.theme
    },
    goToStoreItems() {
      const currentPath = window.location.pathname
      window.location.href = `${currentPath.replace(
        '/ebooks',
        '/read'
      )}/store_items`
    },
    fetchItems() {
      storeItemsApiService
        .fetch(this.optional_params)
        .then((response) => {
          this.storeItems = response.data.entries
          this.total_count = response.data.total_count
        })
        .finally(() => {
          this.$emit(EMITTERS.STORE_ITEM_APP_LOADED)
        })
    },
    openPreview(item) {
      this.storeItem = item
      this.showPreview = true
    },
    closePreview() {
      this.storeItem = {}
      this.showPreview = false
    },
  },
}
</script>

<style scoped lang="scss">
.info-block {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  .text-light {
    color: #ffffff;
  }

  .title {
    font-family: DM Sans, serif;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    color: #323232;
  }

  .link {
    font-family: DM Sans, serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    color: #a6a6a6;
    cursor: pointer;
  }
}

.empty-list {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #8f8a9b;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  margin: auto;
  width: 100%;
  height: 140px;
  background: #2b2834;
}
</style>
