<template>
  <div class="store-items-resource">
    <ScrollUpButton />
    <div class="store-items-resource__title">
      {{ $vueTranslate('study_tools.store_items.title') }}
      <div
        v-if="viewAllVisibility"
        class="store-items-resource__link"
        @click="goToStoreItems()"
      >
        {{ `${$vueTranslate('store_items.preview_store_item.view_all')} >` }}
      </div>
    </div>
    <App
      :is-light="false"
      @[EMITTERS.STORE_ITEM_APP_LOADED]="storeItemsLoaded"
    />
  </div>
</template>

<script>
import ScrollUpButton from './elements/ScrollUpButton'
import App from '../../store_items/App'
import { EMITTERS } from '../../shared/utils/emitters'

export default {
  name: 'StoreItems',
  components: { App, ScrollUpButton },
  data() {
    return {
      EMITTERS,
      viewAllVisibility: false,
    }
  },
  methods: {
    isAllTabPresent() {
      return document.getElementsByClassName('all-card').length === 1
    },
    goToStoreItems() {
      window.location.href = `${window.location.pathname}/store_items`
    },
    storeItemsLoaded() {
      this.viewAllVisibility = this.isAllTabPresent()
    },
  },
}
</script>

<style lang="scss">
.store-items-resource {
  .info-block {
    display: none !important;
  }
}

.store-items-resource__title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 16px 0;
  color: #ffffff;
  font-size: 20px;
}

.store-items-resource__link {
  font-size: 16px;
  cursor: pointer;
}
</style>
