<template>
  <div class="main-block-wrapper row">
    <div class="main-block row">
      <ul class="navigation">
        <li v-for="tab of routes" :key="tab.name">
          <TutorialStep
            v-if="tutorialActive && tab.name === 'Notes'"
            :step-positions="[11]"
            :step-number="tutorialStep"
            @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="stepChanged"
          />
          <router-link
            :class="maybeActive(tab.path)"
            :to="routerLinkTo(tab.path)"
          >
            {{ tab.name }}
          </router-link>
          <div v-if="displayTooltip(tab)" class="tooltip-wrapper">
            <div class="study-tools-tooltip">{{ tab.tooltip }}</div>
          </div>
        </li>
      </ul>
      <div
        class="router-view-block"
        :class="{ 'router-view-block__store-items': isStoreItemTab() }"
      >
        <router-view
          @[EMITTERS.CHANGE_TUTORIAL_STEP_EMITTER]="currentStepUpdated"
        ></router-view>
      </div>
      <div class="activities-block" style="display: none;">
        <RecentActivities />
      </div>
    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'

import RecentActivities from './components/RecentActivities'
import { SITES } from '../shared/utils/view_options'
import { STUDY_TOOLS_ROUTES } from '../shared/utils/routes'
import VideoResourcesApiService from './services/videoResourcesApi.service'
import TutorialStep from './components/elements/TutorialStep'
import { TutorialApiService } from './services'
import { EMITTERS } from '../shared/utils/emitters'

export default {
  name: 'StudyToolsAppComponent',
  components: {
    RecentActivities,
    RouterLink,
    RouterView,
    TutorialStep,
  },
  data() {
    return {
      SITES,
      STUDY_TOOLS_ROUTES,
      routes: [],
      tutorialStep: 11,
      tutorialActive: false,
      EMITTERS: EMITTERS,
    }
  },
  mounted() {
    this.resetStorage()
  },
  created() {
    this.initRoutes()
    this.getTutorial()
  },
  methods: {
    isStoreItemTab() {
      return this.$router.currentRoute.value.path === '/store_items'
    },
    resetStorage() {
      this.$store.dispatch('resetNote')
    },
    displayTooltip(tab) {
      if (!tab.tooltip) {
        return false
      }

      let lastShownAt = localStorage.getItem(this.tooltipStorageItemKey(tab))
      let numberOfShows = localStorage.getItem(
        this.tooltipStorageItemKey(tab) + '_number_of_shows'
      )
      numberOfShows = parseInt(numberOfShows || 0)

      const today = new Date()
      lastShownAt = new Date(lastShownAt)

      if (numberOfShows && numberOfShows >= 7) {
        return false
      }
      if (!lastShownAt) {
        localStorage.setItem(this.tooltipStorageItemKey(tab), today.toString())
        this.setNumberOfShows(this.tooltipStorageItemKey(tab), 1)
        return true
      }
      lastShownAt.setDate(lastShownAt.getDate() + 1)
      if (lastShownAt < today) {
        this.setNumberOfShows(
          this.tooltipStorageItemKey(tab),
          numberOfShows + 1
        )
        localStorage.setItem(this.tooltipStorageItemKey(tab), today.toString())
      }
      return true
    },
    tooltipStorageItemKey(tab) {
      return 'study_tools_tooltip_' + tab.name
    },
    setNumberOfShows(tabName, number) {
      localStorage.setItem(tabName + '_number_of_shows', number.toString())
    },
    initRoutes() {
      VideoResourcesApiService.getVideoResourcesInfo(this.optional_params)
        .then((response) => {
          this.routes = STUDY_TOOLS_ROUTES

          const responseData = response.data

          if (responseData.total_count === 0) {
            this.routes = this.routes.filter(
              (el) => el.name !== 'Video Resources'
            )
            this.$router.push(this.routes[0])
          }

          this.defaultRedirect()
        })
        .catch(console.error)
    },
    defaultRedirect() {
      const imageId = this.getImageIdFromUrlParams()
      if (imageId) {
        return this.$router.push('/video_resources/images')
      }
      if (this.$router.currentRoute.value.path === '/') {
        this.$router.push(this.routes[0])
      }
    },
    getImageIdFromUrlParams() {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get('video-resources-image')
    },
    getTutorial() {
      TutorialApiService.getTutorial()
        .then((response) => {
          // noinspection JSUnresolvedVariable
          this.tutorialActive = response.data.tutorial_active
          this.tutorialStep = response.data.tutorial_step
        })
        .catch(console.error)
    },
    stepChanged(data) {
      if (this.tutorialStep > data.number) return
      const nextStep = data.number + 1
      TutorialApiService.updateTutorial({
        tutorial_step: nextStep,
      })
        .then((_response) => {
          this.tutorialStep = nextStep
        })
        .catch(console.error)
    },
    currentStepUpdated(data) {
      this.tutorialStep = data
    },
    routerLinkTo(path) {
      return path.split('/:')[0]
    },
    maybeActive(path) {
      const currentPath = this.$router.currentRoute.value.path
      return currentPath.includes(this.routerLinkTo(path)) ? 'active' : ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../shared/assets/styles/global';

.main-block-wrapper {
  position: relative;
  width: 100%;
  background-color: #1c1923;
  font-family: 'DM Sans', sans-serif;
  margin: 0 0 30px 0;
}

.main-block {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 1.688rem;
}

.recent ul,
.recent ol {
  margin-bottom: 0;
}

ul.navigation {
  width: 100%;
  padding-top: 18px;
  padding-left: 0;
  list-style-type: none;
  margin: 0 16px;
  border-bottom: 2px #32303c solid;

  li {
    display: inline-flex;
    margin-bottom: -2px;
    position: relative;

    a {
      font-size: 16px;
      color: #8f8a9b;
      padding: 12px 16px;
      font-weight: 500;
      width: 160px;
      text-align: center;
    }

    a.active {
      color: $white;
      border-bottom: 2px #6998ae solid;
    }
  }
}

.router-view-block {
  position: unset;
  margin-bottom: 18px;
  width: 70%;
  padding-right: 27px;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.activities-block {
  align-items: center;
}

.video-resources-block {
  width: 70%;
  padding-right: 26px;
  padding-bottom: 16px;
}

.tooltip-wrapper {
  position: absolute;
  top: -18px;
  margin-left: 50%;
  transform: translateX(-50%);
  .study-tools-tooltip {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    padding: 2px 10px;
    background: #b56941;
    border-radius: 0 0 5px 5px;
    color: #ffffff;
  }
}

.router-view-block__store-items {
  width: 100%;
}
</style>
