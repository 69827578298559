import axios from 'axios'

import { API_ROUTES, BASE_API_CONFIG } from '../../../shared/utils/api'

export default {
  create(store_item, optional_params) {
    const required_params = {
      title: store_item.title,
      author: store_item.author,
      sku: store_item.sku,
      description: store_item.description,
      thumbnail_url: store_item.thumbnail_url,
      cad_button_title: store_item.cad_store_name,
      cad_button_link: store_item.cad_store_link,
      usd_button_title: store_item.usd_store_name,
      usd_button_link: store_item.usd_store_link,
    }

    const data = {
      ...optional_params,
      ...required_params,
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.admin_store_items,
      data,
    })
  },
  fetch(optional_params) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.admin_store_items,
      params: {
        ...optional_params,
      },
    })
  },
  destroy(id, optional_params) {
    const data = {
      ...optional_params,
      ...{},
    }

    return axios.request({
      ...BASE_API_CONFIG,
      method: 'DELETE',
      url: API_ROUTES.admin_store_item(id),
      data,
    })
  },
  sync(sku) {
    return axios.request({
      ...BASE_API_CONFIG,
      method: 'POST',
      url: API_ROUTES.admin_store_items_sync(sku),
    })
  },
  async search(sku) {
    return await axios.request({
      ...BASE_API_CONFIG,
      method: 'GET',
      url: API_ROUTES.admin_store_items_search_sku,
      params: {
        sku: sku,
      },
    })
  },
}
