<template>
  <div class="article-card-block">
    <span v-if="isNew" class="article-new-label">
      New
    </span>
    <span class="card-subject">{{ subject.name }}</span>

    <a class="card-link" :title="title" :href="articleUrl()">
      <img class="thumbnail" :alt="title" :src="coverUrl" />
      <span class="card-title" :class="{ 'card-title__light': isLight }">
        {{ title }}
      </span>
    </a>
    <span
      v-if="description"
      class="card-description"
      :class="{ 'card-description__light': isLight }"
    >
      {{ description }}
    </span>

    <div class="topics-block">
      <span
        v-for="topic in topics"
        :key="topic.id"
        class="topic"
        :class="{ topic__light: isLight }"
      >
        <a class="topic-link" :title="topic.name" :href="getTopicUrl(topic)">
          {{ topic.name }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArticleCard',
  props: {
    itemId: {
      required: true,
      type: Number,
    },
    title: {
      required: true,
      default: '',
      type: String,
    },
    description: {
      required: true,
      default: '',
      type: String,
    },
    coverUrl: {
      required: true,
      default: '',
      type: String,
    },
    subject: {
      required: true,
      type: Object,
    },
    topics: {
      required: false,
      default: () => [],
      type: Array,
    },
    searchQuery: {
      required: true,
      default: '',
      type: String,
    },
    settings: {
      required: false,
      default: () => ({
        watch_host: '',
        main_host: '',
        events_host: '',
      }),
      type: Object,
    },
    isLight: {
      required: true,
      default: true,
      type: Boolean,
    },
    isNew: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {},
  methods: {
    articleUrl() {
      const protocol = window.location.protocol
      const host = this.settings.main_host

      return `${protocol}//${host}/read/articles/${this.itemId}?search_from=web`
    },
    getTopicUrl(topic) {
      const protocol = window.location.protocol
      const host = this.settings.main_host

      return `${protocol}//${host}/read/topics/${topic.slug}`
    },
  },
}
</script>

<style lang="scss" scoped>
.article-card-block {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 260px;
  flex: 1;

  .thumbnail {
    height: 140px;
    width: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover;', serif;
    margin-bottom: 8px;
  }
}

.article-card__content {
  display: flex;
  flex-direction: column;
}

.card-title {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  font-family: 'DM Sans', sans-serif !important;
  cursor: pointer;
}

.card-title__light {
  color: #323232;
}

.card-description {
  margin-top: 4px;
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  font-family: 'DM Sans', sans-serif !important;
  white-space: break-spaces;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.card-description__light {
  color: #5c5c5c;
}

.topics-block {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  gap: 6px;
}

.topic {
  padding: 4px 10px 4px;
  font-size: 12px;
  line-height: 16px;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 400;
  background-color: #3b3946;
  color: #d1d3d4;
  border: 1px solid #444050;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.topic__light {
  color: #5c5c5c;
  background-color: #fff;
  border: 1px solid #d1d3d4;
}

.card-link,
.topic-link {
  color: inherit;
  white-space: break-spaces;
  cursor: pointer;
  line-height: 0;
}

.footnote {
  font-size: 0.875rem;
}

.card-subject {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  font-size: 12px;
  color: #bb711a;
}

.article-new-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 0.75rem;
  position: absolute;
  width: 49px;
  height: 1.5rem;
  left: 0;
  top: 19px;
  background: #d18f42;
  border-radius: 0 4px 4px 0;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  font-family: 'DM Sans', serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
}
</style>
