<template>
  <div class="main-block-wrapper">
    <FlashNotification custom-class="admin" site="profile" :theme="'light'" />
    <div class="main-block">
      <div class="store-items__sidebar">
        <div class="store-items__title">
          {{ $vueTranslate(`admin.store_items.app.${getEntityType()}_header`) }}
        </div>
        <div class="store-items__tab">
          <a href="#" class="active">
            {{ $vueTranslate('admin.store_items.app.header') }}
          </a>
        </div>
      </div>
      <div class="store-items__container">
        <span class="store-items__container-title">
          {{ $vueTranslate('admin.store_items.app.header') }}
        </span>
        <div class="store-items__block">
          <div class="container">
            <span class="store-items__sub-title">
              {{ $vueTranslate('admin.store_items.app.add_title') }}
            </span>
            <div class="store-items__buttons-block">
              <div class="store-items__button" @click="showSearch()">
                {{ $vueTranslate('admin.store_items.app.link_from_store') }}
              </div>
            </div>
          </div>

          <div v-if="displaySearch" class="store-items__search-block">
            <div v-if="!searching" class="container">
              <span class="title">
                {{ $vueTranslate('admin.store_items.app.title') }}
              </span>
              <input
                v-model="searchInput"
                class="store-items__search-input"
                placeholder="SKU"
              />

              <div class="store-items__search-actions-block">
                <div class="store-items__button" @click="beginSearch">
                  {{ $vueTranslate('admin.store_items.app.submit') }}
                </div>
                <div
                  class="store-items__button store-items__button-white"
                  @click="cancelAction()"
                >
                  {{ $vueTranslate('admin.store_items.app.cancel') }}
                </div>
              </div>

              <ItemForm
                v-if="isStoreItemPresent()"
                :sku="searchInput"
                :item="storeItem"
                @[EMITTERS.STORE_ITEM_FORM_UPDATED]="updateStoreItem"
              />
            </div>
            <LoaderComponent v-else class="loader" />
          </div>
        </div>
        <div
          v-if="isStoreItemPresent()"
          class="store-items__search-actions-block"
        >
          <div class="store-items__button" @click="saveStoreItem">
            {{ $vueTranslate('admin.store_items.app.save_store_item') }}
          </div>
          <div
            class="store-items__button store-items__button-white"
            @click="cancelAction()"
          >
            {{ $vueTranslate('admin.store_items.app.cancel') }}
          </div>
        </div>
        <div v-if="showStoreItems" class="assigned-store-items-block">
          <span class="title">
            {{ $vueTranslate('admin.store_items.app.create_store_items') }}
          </span>

          <EntriesCarousel
            v-if="isCarouselVisible()"
            :items="storeItems"
            @[EMITTERS.STORE_ITEM_UPDATE]="updateItem"
          />
          <LoaderComponent v-else class="loader" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isEmpty } from 'lodash'

import LoaderComponent from '../../shared/components/Loader'
import storeItemsApiService from './services/storeItemsApi.service'
import { EMITTERS } from '../../shared/utils/emitters'
import FlashNotification from '../../notification/components/FlashNotification'
import ItemForm from './componenets/ItemForm'
import EntriesCarousel from './componenets/EntriesCarousel'

export default {
  name: 'App',
  components: {
    EntriesCarousel,
    ItemForm,
    FlashNotification,
    LoaderComponent,
  },
  data() {
    return {
      EMITTERS,
      displaySearch: false,
      searching: false,
      searchInput: '',
      storeItem: {},
      storeItems: [],
      loading: false,
      showStoreItems: false,
    }
  },
  mounted() {
    this.fetchStoreItems()
  },
  methods: {
    getEntityType() {
      return this.optional_params.entity_type.toLowerCase()
    },
    updateItem(items) {
      this.storeItems = items
    },
    updateStoreItem(item) {
      this.storeItem = item
    },
    fetchStoreItems() {
      this.loading = true
      storeItemsApiService
        .fetch(this.optional_params)
        .then((response) => {
          this.storeItems = response.data
        })
        .finally(() => {
          this.loading = false
          this.showStoreItems = this.storeItems.length > 0
        })
    },
    showSearch() {
      this.displaySearch = true
    },
    cancelAction() {
      this.displaySearch = false
      this.searchInput = ''
      this.storeItem = {}
    },
    beginSearch() {
      this.searching = true
      storeItemsApiService
        .search(this.searchInput)
        .then((response) => {
          if (response.data && response.data.error === 'no_item_found') {
            this.emitter.emit(EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, {
              message: 'No items found by provided SKU',
            })
            return
          }

          this.storeItem = response.data
        })
        .finally(() => {
          this.searching = false
        })
    },
    isStoreItemPresent() {
      return !isEmpty(this.storeItem)
    },
    isCarouselVisible() {
      return !this.loading && !this.searching
    },
    saveStoreItem() {
      this.searching = true

      storeItemsApiService
        .create(this.storeItem, this.optional_params)
        .then((response) => {
          if (!this.handleResponseErrors(response)) {
            this.searching = false
            return
          }

          if (response.data && response.data.success === false) {
            this.searching = false
            this.emitter.emit(EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, {
              message: response.data.message,
            })
            return
          }

          this.onStoreItemSaved(response.data.message)
        })
        .finally(() => {
          this.fetchStoreItems()
        })
    },

    handleResponseErrors(response) {
      const errorMessages = {
        400: 'Oops! It seems like something went wrong with your request. Double-check the info you provided and try again.',
        500: "Looks like our servers are taking a coffee break ☕. We're working to fix it, please try again soon!",
      }

      if (errorMessages[response.status]) {
        this.emitter.emit(EMITTERS.SHOW_ERROR_NOTIFICATION_EMITTER, {
          message: errorMessages[response.status],
        })
        return false
      }

      return true
    },
    onStoreItemSaved(message) {
      this.displaySearch = false
      this.searchInput = ''
      this.storeItem = {}
      this.showStoreItems = true

      this.emitter.emit(EMITTERS.SHOW_NOTIFICATION_EMITTER, {
        message: message,
      })

      this.searching = false
    },
  },
}
</script>

<style lang="scss" scoped>
.main-block-wrapper {
  position: relative;
  width: 100%;
  font-family: 'DM Sans', sans-serif;
  margin-bottom: 32px;
}

.main-block {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}

.store-items__sidebar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 17%;
  padding-right: 16px;
  gap: 8px;
}

.store-items__container {
  width: 83%;
  display: flex;
  flex-direction: column;
  padding-top: 28px;

  .store-items__container-title {
    font-family: DM Sans, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #323232;
    margin-bottom: 16px;
  }
}

.store-items__block {
  max-width: 959px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;

  .container {
    padding-left: 22px;
    padding-top: 11px;
  }
}

.store-items__sub-title {
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #5c5c5c;
}

.store-items__buttons-block {
  margin-top: 16px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.store-items__button {
  height: 32px;
  top: 46px;
  left: 22px;
  padding: 6px 12px 6px 12px;
  gap: 24px;
  border-radius: 4px;
  background-color: #323232;
  color: #ffffff;
  font-family: DM Sans, serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
}

.store-items__button-disabled {
  background-color: #a6a6a6;
  cursor: not-allowed;
}

.store-items__button-white {
  color: #323232;
  background-color: #ffffff;
  border: 1px solid #d1d3d4;
  box-shadow: 0 1px 4px 0 #0000001a;
}

.store-items__tab {
  display: flex;

  a {
    padding: 6px 12px;
    border-radius: 3px;
    color: #323232;
    text-decoration: none;

    &.active {
      background: #414042;
      color: #ffffff;
    }
  }
}

.store-items__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #323232;
}

.store-items__search-block {
  border-top: 1px solid #eaedee;
  margin-bottom: 16px;

  .title {
    font-family: DM Sans, serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    color: #5c5c5c;
  }

  .loader {
    margin-top: 8px;
    margin-bottom: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.store-items__search-input {
  margin-top: 8px;
  width: 517px;
  height: 32px;
  top: 145px;
  left: 22px;
  border-radius: 2px;
  padding: 12px 16px 12px 16px;
  border: 1px solid #d1d3d4;
  font-family: DM Sans, serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  &:focus-visible {
    outline: none;
  }

  margin-bottom: 12px;
}

.store-items__search-actions-block {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-bottom: 12px;
}

.assigned-store-items-block {
  margin-bottom: 8px;

  .title {
    font-family: DM Sans, serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    color: #323232;
  }
}
</style>
