export const EMITTERS = {
  CLEAR_BOOKMARK_FORM_EMITTER: 'clearBookmarkForm',
  TOGGLE_TAG_BLOCK_EMITTER: 'toggleTagBlock',
  BOOKMARK_DELETED_EMITTER: 'bookmarkDeleted',
  BOOKMARK_CREATED_EMITTER: 'bookmarkCreated',
  BOOKMARK_UPDATED_EMITTER: 'bookmarkUpdated',
  BOOKMARK_EDIT_EMITTER: 'bookmarkEdit',
  BOOKMARK_ORDER_EMITTER: 'bookmarkOrderChanged',
  COMMENT_CREATED_EMITTER: 'commentCreated',
  COMMENT_CANCEL_EMITTER: 'commentCancel',
  COMMENT_UPDATED_EMITTER: 'commentUpdated',
  COMMENT_DELETE_EMITTER: 'commentDelete',
  COMMENT_TOGGLE_ACTIONS_EMITTER: 'commentToggleActions',
  COMMENT_REPORT_ACTION: 'commentReportAction',
  COMMENT_REPORTED: 'commentReported',
  LINK_CREATED_EMITTER: 'linkCreated',
  LINK_DELETED_EMITTER: 'linkDeleted',
  TRANSCRIPT_CREATED_EMITTER: 'transcriptCreated',
  TRANSCRIPT_DELETED_EMITTER: 'transcriptDeleted',
  TRANSCRIPT_CANCEL_EMITTER: 'transcriptUpdateCanceled',
  TRANSCRIPT_EDIT_EMITTER: 'transcriptEdit',
  TRANSCRIPT_UPDATED_EMITTER: 'transcriptUpdated',
  TRANSCRIPT_SELECTED_EMITTER: 'transcriptSelected',
  TRANSCRIPT_FAILED_EMITTER: 'transcriptFailed',
  TRANSCRIPT_GENERATE_EMITTER: 'transcriptGenerate',
  TRANSCRIPT_AUTOSCROLL: 'transcriptAutoscroll',
  TRANSCRIPT_CURRENT: 'transcriptCurrent',
  DOCUMENT_CREATED_EMITTER: 'documentCreated',
  DOCUMENT_DELETED_EMITTER: 'documentDeleted',
  DOCUMENT_CANCEL_EMITTER: 'documentUpdateCanceled',
  DOCUMENT_EDIT_EMITTER: 'documentEdit',
  DOCUMENT_UPDATED_EMITTER: 'documentUpdated',
  EBOOK_CREATED_EMITTER: 'ebookCreated',
  EBOOK_DELETED_EMITTER: 'ebookDeleted',
  EBOOK_CANCEL_EMITTER: 'ebookUpdateCanceled',
  EBOOK_EDIT_EMITTER: 'ebookEdit',
  EBOOK_UPDATED_EMITTER: 'ebookUpdated',
  GUIDE_CREATED_EMITTER: 'guideCreated',
  GUIDE_DELETED_EMITTER: 'guideDeleted',
  GUIDE_CANCEL_EMITTER: 'guideUpdateCanceled',
  GUIDE_EDIT_EMITTER: 'guideEdit',
  GUIDE_UPDATED_EMITTER: 'guideUpdated',
  FILE_SELECTED_EMITTER: 'fileSelected',
  EPISODE_CHANGED_EMITTER: 'episodeChanged',
  IMAGE_CREATED_EMITTER: 'imageCreated',
  IMAGE_DELETED_EMITTER: 'imageDeleted',
  IMAGE_CANCEL_EMITTER: 'imageUpdateCanceled',
  IMAGE_EDIT_EMITTER: 'imageEdit',
  IMAGE_UPDATED_EMITTER: 'imageUpdated',
  AFTER_IMAGE_CHANGE_EMITTER: 'afterImageChange',
  IMAGE_SELECTED_EMITTER: 'imageSelected',
  IMAGE_COPYRIGHT_CHANGED: 'imageCopyrightChanged',
  IMAGE_COPYRIGHT_SHOW_ERROR: 'imageCopyrightValidate',
  NOTE_DELETED_EMITTER: 'noteDeleted',
  NOTE_CREATED_EMITTER: 'noteCreated',
  NOTE_UPDATED_EMITTER: 'noteUpdated',
  NOTE_EDIT_EMITTER: 'editNote',
  WATCH_HISTORY_EPISODE_DELETED: 'watchHistoryEpisodeDeleted',
  SHOW_NOTIFICATION_EMITTER: 'showNotification',
  SHOW_ERROR_NOTIFICATION_EMITTER: 'showErrorNotification',
  CLOSE_DIALOG_EMITTER: 'closeDialog',
  VIDEO_RESOURCES_CLOSE_IMAGE_MODAL_EMITTER: 'videoResourcesCloseImageModal',
  TAG_ADDED_EMITTER: 'tagAdded',
  CHANGE_TUTORIAL_STEP_EMITTER: 'tutorialStepChanged',
  DATE_PICKED: 'datePicked',
  DATE_PICKED_FORMATTED: 'datePickedFormatted',
  RANGE_APPLIED: 'rangeApplied',
  SCHEDULE_IMPORT_STARTED: 'scheduleImportStarted',
  SCHEDULE_DATA_IMPORTED: 'scheduleDataImported',
  SCHEDULE_SWIPE_CLICK: 'scheduleSwipeClick',
  SCHEDULE_SWIPE_TO_END: 'scheduleSwipeToEnd',
  REGION_INIT: 'regionInit',
  SCHEDULE_APP_INIT: 'scheduleAppInit',
  REGION_SELECTED: 'regionSelected',
  TIMEZONE_SELECTED: 'timezoneSelected',
  AVAILABLE_DATES_LOADED: 'availableDatesLoaded',
  SCHEDULE_POP_UP_OPENED: 'scheduleAppPopUpOpened',
  FILTER_BOOKMARK_TAG_UPDATED: 'filterBookmarkTagUpdated',
  VIDEO_PLAYER_READY: 'videoPlayerReady',
  HIGHLIGHT_NEWS_BOOKMARK: 'highlightNewsBookmark',
  NEWS_BOOKMARK_CHANGED: 'newsBookmarkChanged',
  HIGHLIGHT_FROM_PROFILE_EMITTER: 'highlightFromProfile',
  CLEAR_SELECTION_NEWS_BOOKMARK: 'clearSelectionNewsBookmark',
  RECENT_SEARCH_ITEM_REMOVE: 'recentSearchItemRemove',
  VRL_CLOSE_MODAL: 'videoResourceLinkCloseModal',
  ADMIN_UPLOAD_VIDEO_CLOSE_MODAL: 'adminUploadVideoCloseModal',
  ADMIN_UPLOAD_AUDIO_CLOSE_MODAL: 'adminUploadAudioCloseModal',
  OPEN_DELETE_DIALOG: 'openDeleteDialog',
  REPORT_CLOSE_MODAL: 'reportCloseModal',
  AUTHORIZATION_CLOSE_MODAL: 'authorizationCloseModal',
  UPDATE_SEARCH_TYPE_SELECTOR: 'updateSearchTypeSelector',
  STORE_ITEM_TOOLTIP: 'storeItemTooltip',
  STORE_ITEM_TOOLTIP_CHANGED: 'storeItemTooltipChanged',
  STORE_ITEM_SAVE: 'storeItemSave',
  STORE_ITEM_FORM_UPDATED: 'storeItemFormUpdated',
  STORE_ITEM_SAVE_BEFORE: 'storeItemSaveBefore',
  STORE_ITEM_SAVE_AFTER: 'storeItemSaveAfter',
  STORE_ITEM_SAVE_ERROR: 'storeItemSaveError',
  STORE_ITEM_RELOAD: 'storeItemReload',
  STORE_ITEM_DESTROY: 'storeItemDestroy',
  STORE_ITEM_PREVIEW: 'storeItemPreview',
  STORE_ITEM_UPDATE: 'storeItemUpdate',
  STORE_ITEM_PREVIEW_ALL: 'storeItemPreviewAll',
  STORE_ITEM_APP_LOADED: 'storeItemAppLoaded',
}
